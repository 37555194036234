import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil } from '~utils';

/**
 * get all teams
 */
export const getTeams = async () => {
	try {
		const {
			data: { GetTeams }
		} = await apollo.query({
			query: gql`
				query {
					GetTeams {
						id
						name
						description
						color
						active
						members {
							id
							name
							firstName
							fullName
							picture
							departmentName
							functionName
						}
					}
				}
			`,
			fetchPolicy: 'no-cache'
		});

		return GetTeams;
	} catch (e) {
		console.log({ e });
	}
};

/**
 * get team
 * @param {String, Number} teamId
 */
export const getTeam = async (teamId) => {
	if (isNil(teamId)) return;
	try {
		const {
			data: { GetTeam }
		} = await apollo.query({
			query: gql`
				query ($TeamId: ID!) {
					GetTeam(TeamId: $TeamId) {
						id
						name
						description
						color
						active
						members {
							id
							name
							firstName
							fullName
							picture
							departmentName
							functionName
						}
					}
				}
			`,
			variables: {
				TeamId: parseInt(teamId, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return GetTeam;
	} catch (e) {
		console.log({ e });
	}
};

/**
 * update team
 * @param {String, Number} teamId
 */
export const updatedTeam = async (teamId, updatedTeam) => {
	if (isNil(teamId)) return;
	try {
		const mutation = gql`
            mutation ($TeamId: ID!, $UpdTeam: TeamInput!) {
                AddUpdTeam(
                    TeamId: $TeamId,
                    UpdTeam: $UpdTeam
                ) ${mutationResTeam}
            }
        `;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				TeamId: parseInt(teamId, 10),
				UpdTeam: updatedTeam
			}
		});

		return data.AddUpdTeam;
	} catch (e) {
		console.log({ e });
	}
};

/**
 * delete team
 * @param {String|Number} teamId
 */
export const delTeam = async (teamId) => {
	if (isNil(teamId)) return;
	try {
		const mutation = gql`
			mutation ($TeamId: ID!) {
				DelTeam(TeamId: $TeamId)
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				TeamId: parseInt(teamId, 10)
			}
		});

		return data.DelTeam;
	} catch (e) {
		console.error({ e });
	}
};

/**
 * get team members
 * @param {String|Number} teamId
 */
export const getTeamMembers = async (teamId) => {
	try {
		const {
			data: { GetTeamMembers }
		} = await apollo.query({
			query: gql`
				query ($TeamId: ID!) {
					GetTeamMembers(TeamId: $TeamId) {
						id
						firstName
						name
						email
						picture
						department
						departmentName
						function
						functionName
					}
				}
			`,
			variables: {
				TeamId: parseInt(teamId, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return GetTeamMembers;
	} catch (e) {
		console.log({ e });
	}
};

/**
 * add team member
 * @param {String, Number} teamId
 * @param {String, Number} userId
 */
export const addTeamMember = async (teamId, userId) => {
	if (isNil(teamId) && isNil(userId)) return;
	try {
		const mutation = gql`
            mutation ($TeamId: ID!, $UserId: ID!) {
                AddTeamMember(
                    TeamId: $TeamId,
                    UserId: $UserId
                ) ${mutationResTeam}
            }
        `;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				TeamId: parseInt(teamId, 10),
				UserId: parseInt(userId, 10)
			}
		});

		return data.AddTeamMember;
	} catch (e) {
		console.log({ e });
	}
};

/**
 * delete team member
 * @param {String|Number} teamId
 * @param {String, Number} userId
 */
export const delTeamMember = async (teamId, userId) => {
	if (isNil(teamId) && isNil(userId)) return;
	try {
		const mutation = gql`
			mutation ($TeamId: ID!, $UserId: ID!) {
				DelTeamMember(TeamId: $TeamId, UserId: $UserId)
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				TeamId: parseInt(teamId, 10),
				UserId: parseInt(userId, 10)
			}
		});

		return data.DelTeamMember;
	} catch (e) {
		console.error({ e });
	}
};

export const getUserTeam = async (userId) => {
	if (isNil(userId)) return;
	try {
		const {
			data: { GetUserTeam }
		} = await apollo.query({
			query: gql`
				query GET_USER_TEAM($userId: ID!) {
					GetUserTeam(UserId: $userId) {
						id
						createdAt
						updatedAt
						projectID
						name
						description
						color
						active
					}
				}
			`,
			variables: {
				userId: parseInt(userId)
			},
			fetchPolicy: 'no-cache'
		});

		return GetUserTeam;
	} catch (e) {
		console.error(e.message);
	}
};

const mutationResTeam = `{
    id
}`;
